import { Title } from 'components/Title';
import * as React from 'react';
import { Layout } from 'components/layout';
import { ArrowLink } from 'components/links';
import { SEO } from 'components/SEO';

import 'reflect-metadata';

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title='Not Found' />

      <main className='container'>
        <div className='layout flex flex-col items-center justify-center text-center text-stone-500'>
          <Title>Page Not Found</Title>
          <ArrowLink className='mt-4 md:text-lg' href='/'>
            Back to Home
          </ArrowLink>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
